// common styles
// screen readers only
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

#wrapper {
  overflow: hidden;
  padding-top: 65px;

  @include media('>=tablet') {
    padding-top: 70px;
  }

  @include media('>=desktop') {
    padding-top: 100px;
  }
}

.bg-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    opacity: 0;
    visibility: hidden;
  }
}

.colorPurple {
  color: $affair;
}

.quote p {
  color: $affair;
  font-size: 110%;
  font-style: oblique;
}