// header styles
#header {
  padding: 12px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  min-width: 320px;
  background: #fff;
  z-index: 999;
  box-shadow:  0 1px 4px rgba(#000, .25);

  @include media('>=desktop') {
    padding: 20px 0;
  }
}

.logo {
  float: left;
  width: 120px;

  a {
    display: block;
  }

  @include media('>=tablet') {
    width: 140px;
  }

  @include media('>=desktop') {
    width: 180px;
  }
}

.navbar {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  background: $gray;
  opacity: 0;
  visibility: hidden;

  .menu-active & {
    opacity: 1;
    visibility: visible;
  }

  .holder {
    @extend %listreset;
    text-align: center;
    padding: 30px 0;
  }

  @include media('>=desktop') {
    float: right;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    background-color: transparent;
    visibility: visible;
    opacity: 1;

    .holder {
      text-align: left;
      padding: 0;
    }
  }
}

.header-info {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  padding: 20px 0 10px 20px;
  position: relative;
  border-top: 1px solid darken($white, 60);
  max-width: 240px;
  margin: 0 auto;

  a {
    color: $white;

    &:hover {
      color: darken($white, 15);
    }
  }

  @include media('>=desktop') {
    display: inline-block;
    vertical-align: middle;
    border-top: none;
    border-left: 1px solid $gray;
    margin-left: 20px;
    padding-top: 10px;
    line-height: 1.2;

    a {
      color: $primary-color;
    }
  }

  @include media('>=widescreen') {
    font-size: 15px;
  }
}
