// main menu styles
.main-menu {
  padding: 0 0 10px;

  &,
  ul {
    @extend %listreset;
  }

  li {
    font-family: $headings-font-family;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    padding: 10px;

    a {
      color: $white;
    }

    &.active a,
    a:hover {
      color: darken($white, 15);
    }
  }

  .lang-item {
    &.current-lang {
      display: none;
    }
  }

  @include media('>=desktop') {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin-right: -10px;

    li {
      font-size: 16px;
      display: inline-block;
      vertical-align: top;

      a {
        color: $gray;
      }

      &.active a,
      a:hover {
        color: $primary-color;
      }
    }
  }

  @include media('>=widescreen') {
    li {
      font-size: 18px;
      padding: 14px;
    }
  }
}

// menu opener
.menu-opener {
  float: right;
  width: 30px;
  height: 24px;
  position: relative;
  margin: 7px 0;

  // animating bars
  .bars {
    &,
    &:before,
    &:after {
      position: absolute;
      width: 30px;
      height: 4px;
      border-radius: 5px;
      background: $primary-color;
    }

    &:before,
    &:after {
      content: '';
    }

    & {
      top: 50%;
      margin-top: -2px;
      transition: transform 75ms cubic-bezier(.55,.055,.675,.19);
    }

    &:before {
      top: -10px;
      transition: top 75ms ease .12s, opacity 75ms ease;
    }

    &:after {
      bottom: -10px;
      transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
    }

    .menu-active & {
      & {
        transform: rotate(45deg);
        transition-delay: 120ms;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
      }

      &:before {
        top: 0;
        opacity: 0;
        transition: top 75ms ease, opacity 75ms ease 120ms;
      }

      &:after {
        bottom: 0;
        transition: bottom 75ms ease, transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
        transform: rotate(-90deg);
      }
    }
  }

  @include media('>=tablet') {
    margin: 10px 0;
  }

  @include media('>=desktop') {
    display: none !important;
  }
}
