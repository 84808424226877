/** article columns styles */
.article-columns {
  padding: 30px 0 0;

  h2 {
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }

  .article-column {
    text-align: center;
    max-width: 480px;
    margin: 0 auto 10px;
    overflow: hidden;
  }

  @include media('>=tablet') {
    padding-top: 60px;

    .article-column {
      margin: 0;
      max-width: 100%;
    }
  }

  @include media('>=desktop') {
    .article-column {
      padding: 0 5%;
    }
  }

  @include media('>=widescreen') {
    padding: 110px 0 0;

    h2 {
      margin-bottom: 60px;
    }
  }
}
