/** experiences page styles */
.page-template-experiences {
  .content-column {
    .page-heading {
      text-align: center;

      @include media('>=480px') {
        text-align: left;
      }
    }
  }
}

.article-wrapper {
  padding: 20px 0;

  @include media('>=phone') {
    padding: 20px 0 40px;
  }

  @include media('>=tablet') {
    padding: 25px 0 80px;

    .taxonomy-page & {
      padding-top: 70px;
    }
  }

  @include media('>=desktop') {
    padding: 30px 0 100px;
  }
}

.experience-article {
  overflow: hidden;
  padding-bottom: 20px;
  font-size: 16px;

  & + & {
    border-top: 1px solid lighten($gray, 60);
    padding-top: 20px;
  }

  @include media('>=480px') {
    padding-bottom: 30px;

    & + & {
      padding-top: 30px;
    }
  }

  @include media('>=desktop') {
    font-size: 18px;
  }

  .thumbnail {
    max-width: 264px;
    margin: 0 auto 10px;

    a {
      display: block;
      opacity: 1;
      transition: opacity 200ms linear;

      &:hover {
        opacity: .85;
      }
    }

    @include media('>=480px') {
      float: left;
      width: 35.892%;
      margin: 0;
    }
  }

  .desc {
    overflow: hidden;
    text-align: center;

    @include media('>=480px') {
      padding-left: 20px;
      text-align: left;
    }

    @include media('>=desktop') {
      padding-left: 30px;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 5px;
  }

  p { margin: 0; }
}

.left-sidebar {
  padding: 30px 0 0;

  @include media('>=tablet') {
    padding: 50px 15px 50px 0;
  }

  @include media('>=desktop') {
    padding-right: 0;
  }
}

.type-list {
  &,
  ul {
    @extend %listreset;
    text-align: center;
  }

  li {
    padding: 5px 0;
    font: 16px/1.325 $roboto-slab;
    display: inline-block;
    vertical-align: top;
  }

  .active > a {
    background: $primary-color;
    color: $white;
    font-weight: 700;
  }

  a {
    display: block;
    padding: 5px 10px;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      color: $white;
      background: $primary-color;
    }

    @include media('>=tablet') {
      display: inline;
      border: none;
      padding: 0;
      color: $gray;

      &:hover {
        background: transparent;
        color: $primary-color;
      }
    }
  }

  @include media('>=480px') {
    text-align: left;
  }

  @include media('>=tablet') {
    li {
      font-size: 18px;
      padding: 14px 0;
      display: block;

      & + li {
        border-top: 1px solid #979797;
      }
    }

    .active a {
      background: transparent;
      color: $primary-color;
    }
  }

  @include media('>=desktop') {
    li {
      font-size: 20px;
      padding: 16px 0;
    }
  }

  @include media('>=widescreen') {
    li {
      font-size: 24px;
      padding: 20px 0;
    }
  }
}
