/** contact form styles */
.contact-form-section {
  padding: 0 0 40px;

  h2 {
    text-align: center;
    margin: 0 0 40px;
  }

  @include media('>=desktop') {
    padding-bottom: 70px;
  }

  @include media('>=widescreen') {
    padding-bottom: 100px;
  }
}

.gform_wrapper {
  .validation_error {
    display: block;
    color: #f00;
    border: 1px solid #f00;
    padding: 15px;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }

  .gform_body {
    &,
    ul,
    ol {
      @extend %listreset;
    }
  }

  .gform_fields {
  }

  .gfield {
    padding-bottom: 20px;

    .gfield_label {
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
    }

    input {
      height: 48px;
    }

    input,
    select,
    textarea {
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    $padding: 1em;
    $icon-width: .65em;

    select {
      height: 48px;
      -moz-appearance: none; // Strip native appearance
      -webkit-appearance: none; // Strip native appearance
      display: inline-block;
      box-sizing: border-box;
      max-width: 100%;
      padding: 0.5em $padding;
      padding-right: $padding + $icon-width + $padding;
      border-radius: 0;
      -webkit-font-smoothing: inherit;
      background-color: transparent;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" preserveAspectRatio="none"><polygon points="0,0 2,0 1,2" fill="' + $gray + '" /></svg>');
      background-size: $icon-width $icon-width * .65; // Arbitary height sets triangle ratio
      background-position: calc(100% - #{$padding}) center;
      background-repeat: no-repeat;
      box-shadow: none;
      cursor: pointer;
      transition: .2s;

      &:focus {
        outline: 0;
      }
      &:disabled {
        opacity: .7;
        cursor: default;
      }
    }

    @include media('>=tablet') {
      padding-bottom: 30px;

      .gfield_label {
        font-size: 18px;
      }
    }
  }

  .ginput_container {
    padding: 10px 0 0;
  }

  .gform_validation_container {
    display: none !important;
  }

  .validation_message {
    color: #f00;
    font-size: 14px;
    display: block;
    padding: 10px 0 0;
  }

  .button {
    min-width: 140px;
    font-size: 16px;
    color: $affair;
  }
}

.contact-person-section {
  padding: 20px 0;

  @include media('>=tablet') {
    padding: 40px 0;
  }
}

.contact-person-box {
  background: #ededed;
  padding: 30px 20px;
  position: relative;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;

  @include media('>=tablet') {
    margin-bottom: 30px;
  }

  .icon {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 15px;
  }

  h3 {
    color: $primary-color;
  }

  .details {
    overflow: hidden;
  }

  .thumb {
    width: 100px;
    margin: 0 auto 20px;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .people-info {
    overflow: hidden;
    padding: 20px 0 0;

    .name {
      display: block;
      font: 700 16px/1.325 $roboto-slab;
      color: $primary-color;
      padding-bottom: 8px;
    }

    span {
      display: block;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 4px;

      i {
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        padding-top: 2px;
        width: 22px;
        text-align: center;
      }

      a {
        color: $outer-space;
      }
    }
  }

  @include media('>=phone') {
    padding: 50px 40px 40px;
    text-align: left;

    .icon {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .thumb {
      float: left;
    }

    h3 {
      padding-right: 80px;
      min-height: 60px;
    }

    .people-info {

      .desc {
        overflow: hidden;
        padding: 10px 0 0 20px;
      }
    }
  }

  @include media('>=tablet') {
    font-size: 16px;

    .icon {
      top: 40px;
      right: 40px;
    }

    .thumb {
      width: 120px;
      margin: 0;
    }

    .people-info {
      .desc {
        padding: 18px 0 0 20px;
      }
    }
  }

  @include media('>=desktop') {
    .thumb {
      width: 120px;
    }

    .details {
      min-height: 150px;
    }
  }

  @include media('>=widescreen') {
    .thumb {
      width: 140px;
    }

    .people-info {
      .desc {
        overflow: hidden;
        padding: 25px 0 0 25px;
      }

      .name {
        font-size: 18px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}
