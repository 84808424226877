/** image gallery styles */
.image-gallery {
  padding: 20px 0 30px;

  .single-case & {
    padding-top: 0;
  }

  @include media('>=tablet') {
    padding: 40px 0 50px;
  }

  @include media('>=desktop') {
    padding: 60px 0 80px;
  }

  @include media('>=widescreen') {
    padding: 80px 0 100px;
  }
}

.image-slideshow {
  overflow: hidden;

  .slideshow-holder {
    position: relative;

    .slick-arrow {
      position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      text-align: center;
      z-index: 2;
      background: $primary-color;
      color: $white;
      white-space: nowrap;
      font-size: 12px;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }

      i {
        display: inline-block;
        vertical-align: middle;
      }

      &:hover {
        background: darken($primary-color, 5);
      }

      @include media('>=phone') {
        width: 40px;
        height: 40px;
        margin-top: -20px;
        font-size: 16px;
      }
    }

    .slide-prev {
      left: 0;
    }

    .slide-next {
      right: 0;
    }
  }

  .slideshow {
    img {
      width: 100%;
      outline: none !important;
    }
  }

  .slider-pagination {
    padding-top: 5px;
    margin: 0 -5px;

    img {
      padding: 5px;
      outline: none !important;
      opacity: 1;
      transition: opacity 200ms;

      &:hover,
      &.slick-current {
        opacity: .8;
      }
    }

    @include media('>=phone') {
      padding-top: 15px;
    }

    @include media('>=tablet') {
      padding-top: 25px;
    }
  }
}
