// helpers mixins
// * use * - @extend %listreset;
%listreset {
  list-style: none;
  margin: 0;
  padding: 0;
}

// clearfix
// * use * - @extend %clearfix;
%clearfix {

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// placeholder mixin for form placeholders
// * use * - @include placeholder{color: #000;}
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &::-moz-placeholder {opacity: 1; @content}
  &:-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
  &.placeholder {@content}
}

// transition Use: @include transition(.5s ease-in-out);
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

//===============================================================
//box-shadow Use: @include box-shadow(0, 0, 7px, rgba(0, 0, 0, .3));
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}

//===============================================================
// border-radius Use; @include border-radius(10px); }
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
