/** instagram styles */
.instagram-block {
  text-align: center;
    padding: 30px 0;

  h3 {
    margin-bottom: 20px;
  }

  .instagram-feed {
    margin: 0 -20px;
  }

  @include media('>=phone') {
    .instagram-feed {
      margin: 0;
    }
  }

  @include media('>=tablet') {
    padding: 50px 0;

    h3 {
      margin-bottom: 40px;
    }

    .instagram-feed {
      padding-bottom: 10px;
    }
  }

  @include media('>=desktop') {
    padding: 70px 0;

    h3 {
      margin-bottom: 50px;
    }

    .instagram-feed {
      padding-bottom: 20px;
    }
  }

  @include media('>=widescreen') {
    padding: 100px 0;

    h3 {
      margin-bottom: 60px;
    }

    .instagram-feed {
      padding-bottom: 30px;
    }
  }
}
