// colors from http://chir.ag/projects/name-that-color ( use this site for custom colors )
$black: #000;
$white: #fff;
$outer-space: #192726;
$gray: #4a4a4a;
$affair: #8a4a97;

// color variable
$primary-color: $affair;

// Use fonts as needed
$helvatica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$times: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;
$roboto: 'Roboto', $helvatica;
$roboto-slab: 'Roboto Slab', $times;

// Base font family
$base-font-sans-serif: $roboto;
$base-font-serif: $times;

// Variable Declaration for body tag
$base-font-family: $base-font-sans-serif !default;
$base-font-size: 16px !default;
$base-font-size-sm: 15px !default;
$base-line-height: 1.375 !default;
$base-text-color: $outer-space !default;
$base-background-color: $white !default;
$base-min-width: 320px;

// Link color and hover declaration
$base-link-color: $primary-color;
$base-link-hover-color: darken($primary-color, 10);

// Paragraph bottom margin reset ** you can set in px too as per need
$paragraph-bottom-margin: 1em;

// Mark tag reset
$mark-color: $white;
$mark-background: $primary-color;

// Headings ** you can set in px too as per need
$heading-margin-bottom: 0.6em;
$headings-font-family: $roboto-slab !default;
$headings-color: inherit !default;
$headings-font-weight: 400 !default;
$h1-font-size: 60px !default;
$h2-font-size: 46px !default;
$h3-font-size: 34px !default;
$h4-font-size: 22px !default;
$h5-font-size: 18px !default;
$h6-font-size: 16px !default;

// Form element variables  ** you can set in px too as per need
$btn-padding: 0.5em 1em !default;
$form-input-border-width: 1px;
$form-input-border-radius: 0;

// Form element variable declaration
$form-element-padding: 0.7em 1em;
$form-element-border-color: #9b9b9b !default;
$form-element-focus-border-color: $primary-color !default;
$placeholder-color: darken(#ccc, 20);
$form-element-color: $black !default;
$base-form-text-color: $black;
$base-label-font-size: 1.5em;

// components variables
$base-radius: 4px;

// selection color
$base-selection-color: $primary-color;
