@charset "UTF-8";
/*!
Theme Name: Weway
Theme URI: http://pigment.se/
Author: Pigment AB
Author URI: http://pigment.se/
Version: 1.0
*/
/*!
 *  The Black Tie Font is commercial software. Please do not distribute.
 */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'Black Tie';
  src: url("assets/fonts/blacktie/solid/BlackTie-Solid-webfont.eot?v=1.0.0");
  src: url("assets/fonts/blacktie/solid/BlackTie-Solid-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("assets/fonts/blacktie/solid/BlackTie-Solid-webfont.woff2?v=1.0.0") format("woff2"), url("assets/fonts/blacktie/solid/BlackTie-Solid-webfont.woff?v=1.0.0") format("woff"), url("assets/fonts/blacktie/solid/BlackTie-Solid-webfont.ttf?v=1.0.0") format("truetype"), url("assets/fonts/blacktie/solid/BlackTie-Solid-webfont.svg?v=1.0.0#black_tiesolid") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Black Tie';
  src: url("assets/fonts/blacktie/bold/BlackTie-Bold-webfont.eot?v=1.0.0");
  src: url("assets/fonts/blacktie/bold/BlackTie-Bold-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("assets/fonts/blacktie/bold/BlackTie-Bold-webfont.woff2?v=1.0.0") format("woff2"), url("assets/fonts/blacktie/bold/BlackTie-Bold-webfont.woff?v=1.0.0") format("woff"), url("assets/fonts/blacktie/bold/BlackTie-Bold-webfont.ttf?v=1.0.0") format("truetype"), url("assets/fonts/blacktie/bold/BlackTie-Bold-webfont.svg?v=1.0.0#black_tiebold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Black Tie';
  src: url("assets/fonts/blacktie/regular/BlackTie-Regular-webfont.eot?v=1.0.0");
  src: url("assets/fonts/blacktie/regular/BlackTie-Regular-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("assets/fonts/blacktie/regular/BlackTie-Regular-webfont.woff2?v=1.0.0") format("woff2"), url("assets/fonts/blacktie/regular/BlackTie-Regular-webfont.woff?v=1.0.0") format("woff"), url("assets/fonts/blacktie/regular/BlackTie-Regular-webfont.ttf?v=1.0.0") format("truetype"), url("assets/fonts/blacktie/regular/BlackTie-Regular-webfont.svg?v=1.0.0#black_tieregular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Black Tie';
  src: url("assets/fonts/blacktie/light/BlackTie-Light-webfont.eot?v=1.0.0");
  src: url("assets/fonts/blacktie/light/BlackTie-Light-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("assets/fonts/blacktie/light/BlackTie-Light-webfont.woff2?v=1.0.0") format("woff2"), url("assets/fonts/blacktie/light/BlackTie-Light-webfont.woff?v=1.0.0") format("woff"), url("assets/fonts/blacktie/light/BlackTie-Light-webfont.ttf?v=1.0.0") format("truetype"), url("assets/fonts/blacktie/light/BlackTie-Light-webfont.svg?v=1.0.0#black_tielight") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Font Awesome Brands';
  src: url("assets/fonts/blacktie/brands/FontAwesomeBrands-Regular-webfont.eot?v=1.0.0");
  src: url("assets/fonts/blacktie/brands/FontAwesomeBrands-Regular-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("assets/fonts/blacktie/brands/FontAwesomeBrands-Regular-webfont.woff2?v=1.0.0") format("woff2"), url("assets/fonts/blacktie/brands/FontAwesomeBrands-Regular-webfont.woff?v=1.0.0") format("woff"), url("assets/fonts/blacktie/brands/FontAwesomeBrands-Regular-webfont.ttf?v=1.0.0") format("truetype"), url("assets/fonts/blacktie/brands/FontAwesomeBrands-Regular-webfont.svg?v=1.0.0#font_awesome_brandsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.bts,
.btb,
.btr,
.btl,
.fab {
  display: inline-block;
  font: normal normal normal 14px/1 "Black Tie";
  font-size: inherit;
  vertical-align: -14.28571429%;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.bts {
  font-weight: 900;
}

.btb {
  font-weight: 700;
}

.btl {
  font-weight: 200;
}

.fab {
  font-family: "Font Awesome Brands";
}

/* makes the font 25% smaller relative to the icon container */
.bt-sm {
  font-size: .7em;
  vertical-align: baseline;
}

/* makes the font 33% larger relative to the icon container */
.bt-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
}

.bt-2x {
  font-size: 2em;
}

.bt-3x {
  font-size: 3em;
}

.bt-4x {
  font-size: 4em;
}

.bt-5x {
  font-size: 5em;
}

.bt-lg,
.bt-2x,
.bt-3x,
.bt-4x,
.bt-5x {
  vertical-align: -30%;
}

.bt-fw {
  width: 1.28571429em;
  text-align: center;
}

.bt-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.bt-ul > li {
  position: relative;
}

.bt-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.bt-li.bt-lg {
  left: -2em;
}

.bt-border {
  padding: .2em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.bts.pull-left,
.btb.pull-left,
.btr.pull-left,
.btl.pull-left,
.fab.pull-left {
  margin-right: .3em;
}

.bts.pull-right,
.btb.pull-right,
.btr.pull-right,
.btl.pull-right,
.fab.pull-right {
  margin-left: .3em;
}

.bt-spin {
  -webkit-animation: bt-spin 2s infinite linear;
  animation: bt-spin 2s infinite linear;
}

.bt-pulse {
  -webkit-animation: bt-spin 1s infinite steps(8);
  animation: bt-spin 1s infinite steps(8);
}

@-webkit-keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.bt-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.bt-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.bt-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.bt-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.bt-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .bt-rotate-90,
:root .bt-rotate-180,
:root .bt-rotate-270,
:root .bt-flip-horizontal,
:root .bt-flip-vertical {
  filter: none;
}

.bt-stack {
  position: relative;
  display: inline-block;
  width: 1.28571429em;
  height: 1em;
  line-height: 1em;
  vertical-align: baseline;
}

.bt-stack-sm {
  position: absolute;
  top: 0;
  left: 0;
  line-height: inherit;
  font-size: .5em;
}

.bt-stack-1x,
.bt-stack-sm {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.bt-inverse {
  color: #ffffff;
}

/* Black Tie uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.bt-bars:before {
  content: "\f000";
}

.bt-envelope:before {
  content: "\f001";
}

.bt-search:before {
  content: "\f002";
}

.bt-search-plus:before {
  content: "\f003";
}

.bt-search-minus:before {
  content: "\f004";
}

.bt-phone:before {
  content: "\f005";
}

.bt-comment:before {
  content: "\f007";
}

.bt-commenting:before {
  content: "\f008";
}

.bt-comments:before {
  content: "\f009";
}

.bt-rss:before {
  content: "\f00a";
}

.bt-times:before {
  content: "\f00c";
}

.bt-times-circle:before {
  content: "\f00d";
}

.bt-clock:before {
  content: "\f00e";
}

.bt-star:before {
  content: "\f010";
}

.bt-star-half:before {
  content: "\f011";
}

.bt-check:before {
  content: "\f012";
}

.bt-check-circle:before {
  content: "\f013";
}

.bt-check-square:before {
  content: "\f014";
}

.bt-th:before {
  content: "\f015";
}

.bt-th-large:before {
  content: "\f016";
}

.bt-heart:before {
  content: "\f017";
}

.bt-heart-half:before {
  content: "\f018";
}

.bt-calendar:before {
  content: "\f019";
}

.bt-shopping-cart:before {
  content: "\f01a";
}

.bt-plus:before {
  content: "\f01b";
}

.bt-plus-circle:before {
  content: "\f01c";
}

.bt-plus-square:before {
  content: "\f01d";
}

.bt-pen:before {
  content: "\f01e";
}

.bt-minus:before {
  content: "\f021";
}

.bt-minus-circle:before {
  content: "\f022";
}

.bt-minus-square:before {
  content: "\f023";
}

.bt-pencil:before {
  content: "\f024";
}

.bt-edit:before {
  content: "\f025";
}

.bt-thumbs-up:before {
  content: "\f026";
}

.bt-thumbs-down:before {
  content: "\f027";
}

.bt-gear:before {
  content: "\f028";
}

.bt-trash:before {
  content: "\f029";
}

.bt-file:before {
  content: "\f02a";
}

.bt-info-circle:before {
  content: "\f02b";
}

.bt-label:before {
  content: "\f02c";
}

.bt-rocket:before {
  content: "\f02d";
}

.bt-book:before {
  content: "\f02e";
}

.bt-book-open:before {
  content: "\f02f";
}

.bt-notebook:before {
  content: "\f030";
}

.bt-camera:before {
  content: "\f031";
}

.bt-folder:before {
  content: "\f032";
}

.bt-quote-left:before {
  content: "\f036";
}

.bt-quote-right:before {
  content: "\f037";
}

.bt-eye:before {
  content: "\f038";
}

.bt-lock:before {
  content: "\f039";
}

.bt-lock-open:before {
  content: "\f03a";
}

.bt-gift:before {
  content: "\f03b";
}

.bt-spinner-clock:before {
  content: "\f03c";
}

.bt-spinner:before {
  content: "\f03d";
}

.bt-wrench:before {
  content: "\f03e";
}

.bt-cloud:before {
  content: "\f040";
}

.bt-cloud-upload:before {
  content: "\f041";
}

.bt-cloud-download:before {
  content: "\f042";
}

.bt-sync:before {
  content: "\f043";
}

.bt-question-circle:before {
  content: "\f044";
}

.bt-share:before {
  content: "\f045";
}

.bt-briefcase:before {
  content: "\f046";
}

.bt-money:before {
  content: "\f047";
}

.bt-megaphone:before {
  content: "\f048";
}

.bt-sign-in:before {
  content: "\f049";
}

.bt-sign-out:before {
  content: "\f04a";
}

.bt-film:before {
  content: "\f04b";
}

.bt-trophy:before {
  content: "\f04c";
}

.bt-code:before {
  content: "\f04d";
}

.bt-light-bulb:before {
  content: "\f04e";
}

.bt-print:before {
  content: "\f050";
}

.bt-fax:before {
  content: "\f051";
}

.bt-video:before {
  content: "\f052";
}

.bt-signal:before {
  content: "\f053";
}

.bt-sitemap:before {
  content: "\f054";
}

.bt-upload:before {
  content: "\f055";
}

.bt-download:before {
  content: "\f056";
}

.bt-key:before {
  content: "\f057";
}

.bt-mug:before {
  content: "\f058";
}

.bt-bookmark:before {
  content: "\f059";
}

.bt-flag:before {
  content: "\f05a";
}

.bt-external-link:before {
  content: "\f05b";
}

.bt-smile:before {
  content: "\f05c";
}

.bt-frown:before {
  content: "\f05d";
}

.bt-meh:before {
  content: "\f05e";
}

.bt-magic:before {
  content: "\f060";
}

.bt-bolt:before {
  content: "\f061";
}

.bt-exclamation-triangle:before {
  content: "\f062";
}

.bt-exclamation-circle:before {
  content: "\f063";
}

.bt-flask:before {
  content: "\f064";
}

.bt-music:before {
  content: "\f065";
}

.bt-push-pin:before {
  content: "\f066";
}

.bt-shield:before {
  content: "\f067";
}

.bt-sort:before {
  content: "\f068";
}

.bt-reply:before {
  content: "\f069";
}

.bt-forward:before {
  content: "\f06a";
}

.bt-reply-all:before {
  content: "\f06b";
}

.bt-forward-all:before {
  content: "\f06c";
}

.bt-bell:before {
  content: "\f06d";
}

.bt-bell-off:before {
  content: "\f06e";
}

.bt-ban:before {
  content: "\f070";
}

.bt-database:before {
  content: "\f071";
}

.bt-hard-drive:before {
  content: "\f072";
}

.bt-merge:before {
  content: "\f073";
}

.bt-fork:before {
  content: "\f074";
}

.bt-wifi:before {
  content: "\f075";
}

.bt-paper-plane:before {
  content: "\f076";
}

.bt-inbox:before {
  content: "\f077";
}

.bt-fire:before {
  content: "\f078";
}

.bt-play:before {
  content: "\f079";
}

.bt-pause:before {
  content: "\f07a";
}

.bt-stop:before {
  content: "\f08b";
}

.bt-play-circle:before {
  content: "\f07b";
}

.bt-next:before {
  content: "\f07c";
}

.bt-previous:before {
  content: "\f07d";
}

.bt-repeat:before {
  content: "\f07e";
}

.bt-fast-forward:before {
  content: "\f080";
}

.bt-fast-reverse:before {
  content: "\f081";
}

.bt-volume:before {
  content: "\f082";
}

.bt-volume-off:before {
  content: "\f083";
}

.bt-volume-up:before {
  content: "\f084";
}

.bt-volume-down:before {
  content: "\f085";
}

.bt-maximize:before {
  content: "\f086";
}

.bt-minimize:before {
  content: "\f087";
}

.bt-closed-captions:before {
  content: "\f088";
}

.bt-shuffle:before {
  content: "\f089";
}

.bt-triangle:before {
  content: "\f08a";
}

.bt-square:before {
  content: "\f08b";
}

.bt-circle:before {
  content: "\f08c";
}

.bt-hexagon:before {
  content: "\f08d";
}

.bt-octagon:before {
  content: "\f08e";
}

.bt-angle-up:before {
  content: "\f090";
}

.bt-angle-down:before {
  content: "\f091";
}

.bt-angle-left:before {
  content: "\f092";
}

.bt-angle-right:before {
  content: "\f093";
}

.bt-angles-up:before {
  content: "\f094";
}

.bt-angles-down:before {
  content: "\f095";
}

.bt-angles-left:before {
  content: "\f096";
}

.bt-angles-right:before {
  content: "\f097";
}

.bt-arrow-up:before {
  content: "\f098";
}

.bt-arrow-down:before {
  content: "\f099";
}

.bt-arrow-left:before {
  content: "\f09a";
}

.bt-arrow-right:before {
  content: "\f09b";
}

.bt-bar-chart:before {
  content: "\f09c";
}

.bt-pie-chart:before {
  content: "\f09d";
}

.bt-circle-arrow-up:before {
  content: "\f0a0";
}

.bt-circle-arrow-down:before {
  content: "\f0a1";
}

.bt-circle-arrow-left:before {
  content: "\f0a2";
}

.bt-circle-arrow-right:before {
  content: "\f0a3";
}

.bt-caret-up:before {
  content: "\f0a4";
}

.bt-caret-down:before {
  content: "\f0a5";
}

.bt-caret-left:before {
  content: "\f0a6";
}

.bt-caret-right:before {
  content: "\f0a7";
}

.bt-long-arrow-up:before {
  content: "\f0a8";
}

.bt-long-arrow-down:before {
  content: "\f0a9";
}

.bt-long-arrow-left:before {
  content: "\f0aa";
}

.bt-long-arrow-right:before {
  content: "\f0ab";
}

.bt-Bold:before {
  content: "\f0ac";
}

.bt-italic:before {
  content: "\f0ad";
}

.bt-underline:before {
  content: "\f0ae";
}

.bt-link:before {
  content: "\f0b0";
}

.bt-paper-clip:before {
  content: "\f0b1";
}

.bt-align-left:before {
  content: "\f0b2";
}

.bt-align-center:before {
  content: "\f0b3";
}

.bt-align-right:before {
  content: "\f0b4";
}

.bt-align-justify:before {
  content: "\f0b5";
}

.bt-cut:before {
  content: "\f0b6";
}

.bt-copy:before {
  content: "\f0b7";
}

.bt-paste:before {
  content: "\f0b8";
}

.bt-photo:before {
  content: "\f0b9";
}

.bt-table:before {
  content: "\f0ba";
}

.bt-ulist:before {
  content: "\f0bb";
}

.bt-olist:before {
  content: "\f0bc";
}

.bt-indent:before {
  content: "\f0bd";
}

.bt-outdent:before {
  content: "\f0be";
}

.bt-undo:before {
  content: "\f0c0";
}

.bt-redo:before {
  content: "\f0c1";
}

.bt-sup:before {
  content: "\f0c2";
}

.bt-sub:before {
  content: "\f0c3";
}

.bt-text-size:before {
  content: "\f0c4";
}

.bt-text-color:before {
  content: "\f0c5";
}

.bt-remove-formatting:before {
  content: "\f0c6";
}

.bt-blockquote:before {
  content: "\f036";
}

.bt-globe:before {
  content: "\f0c7";
}

.bt-map:before {
  content: "\f0c8";
}

.bt-map-arrow:before {
  content: "\f0c9";
}

.bt-map-marker:before {
  content: "\f0ca";
}

.bt-map-pin:before {
  content: "\f0cb";
}

.bt-home:before {
  content: "\f0cc";
}

.bt-building:before {
  content: "\f0cd";
}

.bt-industry:before {
  content: "\f0ce";
}

.bt-desktop:before {
  content: "\f0d0";
}

.bt-laptop:before {
  content: "\f0d1";
}

.bt-tablet:before {
  content: "\f0d2";
}

.bt-mobile:before {
  content: "\f0d3";
}

.bt-tv:before {
  content: "\f0d4";
}

.bt-radio-checked:before {
  content: "\f0d5";
}

.bt-radio-unchecked:before {
  content: "\f08c";
}

.bt-checkbox-checked:before {
  content: "\f014";
}

.bt-checkbox-unchecked:before {
  content: "\f08b";
}

.bt-checkbox-intermediate:before {
  content: "\f023";
}

.bt-user:before {
  content: "\f0d6";
}

.bt-user-male:before {
  content: "\f0d6";
}

.bt-user-female:before {
  content: "\f0d7";
}

.bt-crown:before {
  content: "\f0d8";
}

.bt-credit-card:before {
  content: "\f0d9";
}

.bt-strikethrough:before {
  content: "\f0da";
}

.bt-eject:before {
  content: "\f0db";
}

.bt-ellipsis-h:before {
  content: "\f0dc";
}

.bt-ellipsis-v:before {
  content: "\f0dd";
}

.fab-facebook:before {
  content: "\f000";
}

.fab-facebook-alt:before {
  content: "\f001";
}

.fab-twitter:before {
  content: "\f002";
}

.fab-linkedin:before {
  content: "\f003";
}

.fab-linkedin-alt:before {
  content: "\f004";
}

.fab-instagram:before {
  content: "\f005";
}

.fab-github:before {
  content: "\f006";
}

.fab-github-alt:before {
  content: "\f007";
}

.fab-googleplus:before {
  content: "\f008";
}

.fab-googleplus-alt:before {
  content: "\f009";
}

.fab-pinterest:before {
  content: "\f00a";
}

.fab-pinterest-alt:before {
  content: "\f00b";
}

.fab-tumblr:before {
  content: "\f00c";
}

.fab-tumblr-alt:before {
  content: "\f00d";
}

.fab-bitcoin:before {
  content: "\f010";
}

.fab-bitcoin-alt:before {
  content: "\f011";
}

.fab-dropbox:before {
  content: "\f012";
}

.fab-stackexchange:before {
  content: "\f013";
}

.fab-stackoverflow:before {
  content: "\f014";
}

.fab-flickr:before {
  content: "\f015";
}

.fab-flickr-alt:before {
  content: "\f016";
}

.fab-bitbucket:before {
  content: "\f017";
}

.fab-html5:before {
  content: "\f018";
}

.fab-css3:before {
  content: "\f019";
}

.fab-apple:before {
  content: "\f01a";
}

.fab-windows:before {
  content: "\f01b";
}

.fab-android:before {
  content: "\f01c";
}

.fab-linux:before {
  content: "\f01d";
}

.fab-dribbble:before {
  content: "\f01e";
}

.fab-youtube:before {
  content: "\f021";
}

.fab-skype:before {
  content: "\f022";
}

.fab-foursquare:before {
  content: "\f023";
}

.fab-trello:before {
  content: "\f024";
}

.fab-maxcdn:before {
  content: "\f025";
}

.fab-gittip:before,
.fab-gratipay:before {
  content: "\f026";
}

.fab-vimeo:before {
  content: "\f027";
}

.fab-vimeo-alt:before {
  content: "\f028";
}

.fab-slack:before {
  content: "\f029";
}

.fab-wordpress:before {
  content: "\f02a";
}

.fab-wordpress-alt:before {
  content: "\f02b";
}

.fab-openid:before {
  content: "\f02c";
}

.fab-yahoo:before {
  content: "\f02d";
}

.fab-yahoo-alt:before {
  content: "\f02e";
}

.fab-reddit:before {
  content: "\f02f";
}

.fab-google:before {
  content: "\f030";
}

.fab-google-alt:before {
  content: "\f031";
}

.fab-stumbleupon:before {
  content: "\f032";
}

.fab-stumbleupon-alt:before {
  content: "\f033";
}

.fab-delicious:before {
  content: "\f034";
}

.fab-digg:before {
  content: "\f035";
}

.fab-piedpiper:before {
  content: "\f036";
}

.fab-piedpiper-alt:before {
  content: "\f037";
}

.fab-drupal:before {
  content: "\f038";
}

.fab-joomla:before {
  content: "\f039";
}

.fab-behance:before {
  content: "\f03a";
}

.fab-steam:before {
  content: "\f03b";
}

.fab-steam-alt:before {
  content: "\f03c";
}

.fab-spotify:before {
  content: "\f03d";
}

.fab-deviantart:before {
  content: "\f03e";
}

.fab-soundcloud:before {
  content: "\f040";
}

.fab-vine:before {
  content: "\f041";
}

.fab-codepen:before {
  content: "\f042";
}

.fab-jsfiddle:before {
  content: "\f043";
}

.fab-rebel:before {
  content: "\f044";
}

.fab-empire:before {
  content: "\f045";
}

.fab-git:before {
  content: "\f046";
}

.fab-hackernews:before {
  content: "\f047";
}

.fab-hackernews-alt:before {
  content: "\f048";
}

.fab-slideshare:before {
  content: "\f049";
}

.fab-twitch:before {
  content: "\f04a";
}

.fab-yelp:before {
  content: "\f04b";
}

.fab-paypal:before {
  content: "\f04c";
}

.fab-google-wallet:before {
  content: "\f04d";
}

.fab-angellist:before {
  content: "\f04e";
}

.fab-cc-visa:before {
  content: "\f050";
}

.fab-cc-mastercard:before {
  content: "\f051";
}

.fab-cc-discover:before {
  content: "\f052";
}

.fab-cc-amex:before {
  content: "\f053";
}

.fab-cc-paypal:before {
  content: "\f054";
}

.fab-cc-stripe:before {
  content: "\f055";
}

.fab-lastfm:before {
  content: "\f056";
}

.fab-whatsapp:before {
  content: "\f057";
}

.fab-medium:before {
  content: "\f058";
}

.fab-meanpath:before {
  content: "\f059";
}

.fab-meanpath-alt:before {
  content: "\f05a";
}

.fab-pagelines:before {
  content: "\f05b";
}

.fab-ioxhost:before {
  content: "\f060";
}

.fab-buysellads:before {
  content: "\f061";
}

.fab-buysellads-alt:before {
  content: "\f062";
}

.fab-connectdevelop:before {
  content: "\f063";
}

.fab-dashcube:before {
  content: "\f064";
}

.fab-forumbee:before {
  content: "\f065";
}

.fab-leanpub:before {
  content: "\f066";
}

.fab-sellsy:before {
  content: "\f067";
}

.fab-shirtsinbulk:before {
  content: "\f068";
}

.fab-simplybuilt:before {
  content: "\f069";
}

.fab-skyatlas:before {
  content: "\f06a";
}

.fab-viacoin:before {
  content: "\f06b";
}

.fab-codiepie:before {
  content: "\f06c";
}

.fab-queue:before {
  content: "\f06d";
}

.fab-queue-alt:before {
  content: "\f06e";
}

.fab-fonticons:before {
  content: "\f070";
}

.fab-fonticons-alt:before {
  content: "\f071";
}

.fab-blacktie:before {
  content: "\f072";
}

.fab-blacktie-alt:before {
  content: "\f073";
}

.fab-xing:before {
  content: "\f090";
}

.fab-vk:before {
  content: "\f091";
}

.fab-weibo:before {
  content: "\f092";
}

.fab-renren:before {
  content: "\f093";
}

.fab-tencent-weibo:before {
  content: "\f094";
}

.fab-qq:before {
  content: "\f095";
}

.fab-wechat:before,
.fab-weixin:before {
  content: "\f096";
}

@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Roboto';
  src: url("assets/fonts/roboto/Roboto-MediumItalic.eot");
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-MediumItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-MediumItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-MediumItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url("assets/fonts/roboto/Roboto-BoldItalic.eot");
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-BoldItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-BoldItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-BoldItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url("assets/fonts/roboto/Roboto-Regular.eot");
  src: local("Roboto"), local("Roboto-Regular"), url("assets/fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Regular.woff") format("woff"), url("assets/fonts/roboto/Roboto-Regular.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url("assets/fonts/roboto/Roboto-Bold.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("assets/fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Bold.woff") format("woff"), url("assets/fonts/roboto/Roboto-Bold.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url("assets/fonts/roboto/Roboto-Medium.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("assets/fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Medium.woff") format("woff"), url("assets/fonts/roboto/Roboto-Medium.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url("assets/fonts/roboto/Roboto-Italic.eot");
  src: local("Roboto Italic"), local("Roboto-Italic"), url("assets/fonts/roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Italic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Italic.woff") format("woff"), url("assets/fonts/roboto/Roboto-Italic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url("assets/fonts/roboto-slab/RobotoSlab-Bold.eot");
  src: local("Roboto Slab Bold"), local("RobotoSlab-Bold"), url("assets/fonts/roboto-slab/RobotoSlab-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto-slab/RobotoSlab-Bold.woff2") format("woff2"), url("assets/fonts/roboto-slab/RobotoSlab-Bold.woff") format("woff"), url("assets/fonts/roboto-slab/RobotoSlab-Bold.ttf") format("truetype"), url("assets/fonts/roboto-slab/RobotoSlab-Bold.svg#RobotoSlab-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url("assets/fonts/roboto-slab/RobotoSlab-Regular.eot");
  src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"), url("assets/fonts/roboto-slab/RobotoSlab-Regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto-slab/RobotoSlab-Regular.woff2") format("woff2"), url("assets/fonts/roboto-slab/RobotoSlab-Regular.woff") format("woff"), url("assets/fonts/roboto-slab/RobotoSlab-Regular.ttf") format("truetype"), url("assets/fonts/roboto-slab/RobotoSlab-Regular.svg#RobotoSlab-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

.main-menu,
.main-menu ul, .banner .icon-link-list, .clients-logo, .type-list,
.type-list ul, .gform_wrapper .gform_body,
.gform_wrapper .gform_body ul,
.gform_wrapper .gform_body ol, .navbar .holder, .footer-menu, .social-links, .payment-icons {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-top:after, .footer-bottom:after {
  content: '';
  display: table;
  clear: both;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-font-variant-ligatures: no-common-ligatures;
  color: #192726;
  background: #fff;
  font: 15px/1.375 "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-width: 320px;
}
@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

a {
  color: #8a4a97;
  transition: background 200ms, color 200ms;
  text-decoration: none;
}
a:hover {
  color: #6b3975;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

@media only screen and (min-width: 1025px) {
  a[href^=tel], a[href^=skype], a[href^=sms] {
    cursor: default;
    pointer-events: none;
  }
}
/*IE 8 WARNING*/
.alert-warning {
  position: fixed;
  z-index: 9999999;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding-top: 10px;
  font-size: 20px;
  text-align: center;
  color: #fff;
  background: #9c2213;
}
.alert-warning a {
  font-weight: 800;
  text-decoration: underline;
  color: #fff;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: inherit;
  font-family: "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  font-weight: 400;
  margin: 0 0 0.6em;
}

h1, .h1 {
  font-size: 30px;
}
@media (min-width: 600px) {
  h1, .h1 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 46px;
  }
}
@media (min-width: 992px) {
  h1, .h1 {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 60px;
  }
}

h2, .h2 {
  font-size: 22px;
}
@media (min-width: 600px) {
  h2, .h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  h2, .h2 {
    font-size: 38px;
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 46px;
  }
}

h3, .h3 {
  font-size: 22px;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 34px;
  }
}

h4, .h4 {
  font-size: 22px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

p {
  margin: 0 0 1em;
}

mark {
  background: #8a4a97;
  color: #fff;
}

label {
  font-size: 1.5em;
}

::selection {
  background: #8a4a97;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #8a4a97;
  /* Gecko Browsers */
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="file"]:focus,
input[type="submit"]:focus {
  outline: none;
}

input[type="search"] {
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

select {
  -webkit-border-radius: 0;
}

input,
textarea,
select {
  font: 100% "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  vertical-align: middle;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  margin: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 1px solid #9b9b9b;
  padding: 0.7em 1em;
  color: #000;
}
input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999999;
}
input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #999999;
}
input[type="text"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999999;
}
input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999999;
}
input[type="text"].placeholder,
input[type="tel"].placeholder,
input[type="email"].placeholder,
input[type="url"].placeholder,
input[type="password"].placeholder,
input[type="search"].placeholder,
textarea.placeholder {
  color: #999999;
}
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
  border-color: #8a4a97;
}

.main-menu {
  padding: 0 0 10px;
}
.main-menu li {
  font-family: "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  padding: 10px;
}
.main-menu li a {
  color: #fff;
}
.main-menu li.active a,
.main-menu li a:hover {
  color: #d9d9d9;
}
.main-menu .lang-item.current-lang {
  display: none;
}
@media (min-width: 992px) {
  .main-menu {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin-right: -10px;
  }
  .main-menu li {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
  }
  .main-menu li a {
    color: #4a4a4a;
  }
  .main-menu li.active a,
  .main-menu li a:hover {
    color: #8a4a97;
  }
}
@media (min-width: 1200px) {
  .main-menu li {
    font-size: 18px;
    padding: 14px;
  }
}

.menu-opener {
  float: right;
  width: 30px;
  height: 24px;
  position: relative;
  margin: 7px 0;
}
.menu-opener .bars, .menu-opener .bars:before, .menu-opener .bars:after {
  position: absolute;
  width: 30px;
  height: 4px;
  border-radius: 5px;
  background: #8a4a97;
}
.menu-opener .bars:before, .menu-opener .bars:after {
  content: '';
}
.menu-opener .bars {
  top: 50%;
  margin-top: -2px;
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.menu-opener .bars:before {
  top: -10px;
  transition: top 75ms ease .12s, opacity 75ms ease;
}
.menu-opener .bars:after {
  bottom: -10px;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.menu-active .menu-opener .bars {
  transform: rotate(45deg);
  transition-delay: 120ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.menu-active .menu-opener .bars:before {
  top: 0;
  opacity: 0;
  transition: top 75ms ease, opacity 75ms ease 120ms;
}
.menu-active .menu-opener .bars:after {
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}
@media (min-width: 768px) {
  .menu-opener {
    margin: 10px 0;
  }
}
@media (min-width: 992px) {
  .menu-opener {
    display: none !important;
  }
}

/** banner styles */
.banner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 340px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}
.banner:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(138, 74, 151, 0.24);
  z-index: 1;
}
.banner.category-banner {
  height: 180px;
}
.banner.category-banner h1 {
  margin: 0;
}
.banner.category-banner .banner-overlay {
  padding: 20px 0;
}
@media (min-width: 600px) {
  .banner {
    height: 440px;
  }
  .banner.category-banner {
    height: 240px;
  }
}
@media (min-width: 768px) {
  .banner {
    height: 580px;
  }
  .banner.category-banner {
    height: 300px;
  }
}
@media (min-width: 992px) {
  .banner {
    height: 620px;
  }
}
.banner:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.banner-overlay {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  white-space: normal;
  font-size: 16px;
  color: #fff;
  padding: 30px 0;
  position: relative;
  z-index: 2;
  text-align: center;
}
@media (min-width: 600px) {
  .banner-overlay {
    font-size: 18px;
    padding: 30px 0;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .banner-overlay {
    font-size: 20px;
  }
}
.banner .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner .holder {
  max-width: 400px;
  margin: 0 auto;
}
@media (min-width: 600px) {
  .banner .holder {
    max-width: 450px;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .banner .holder {
    max-width: 550px;
  }
}
@media (min-width: 992px) {
  .banner .holder {
    max-width: 600px;
  }
}
@media (min-width: 1200px) {
  .banner .holder {
    max-width: 650px;
  }
}
.banner h1 {
  font-weight: 700;
  margin: 0 0 15px;
}
.banner.have-icons {
  height: 460px;
}
.banner.have-icons .banner-overlay {
  padding-bottom: 120px;
}
@media (min-width: 600px) {
  .banner.have-icons {
    height: 540px;
  }
  .banner.have-icons .banner-overlay {
    padding-bottom: 170px;
  }
}
@media (min-width: 768px) {
  .banner.have-icons {
    height: 640px;
  }
  .banner.have-icons .banner-overlay {
    padding-bottom: 185px;
  }
}
@media (min-width: 992px) {
  .banner.have-icons {
    height: 740px;
  }
}
.banner-link-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.78);
  white-space: normal;
  z-index: 2;
}
@media (min-width: 600px) {
  .banner-link-overlay {
    padding: 20px 0;
  }
}
.banner .icon-link-list {
  text-align: center;
  overflow: hidden;
  margin: 0 -10px;
}
.banner .icon-link-list li {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  padding: 0 6px;
  min-width: 22%;
}
.banner .icon-link-list li a {
  display: block;
}
.banner .icon-link-list li img {
  max-width: 24px;
}
.banner .icon-link-list li strong {
  display: block;
  font: 700 12px/1.2 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding-top: 15px;
}
@media (min-width: 600px) {
  .banner .icon-link-list li {
    min-width: 120px;
    padding: 10px;
  }
  .banner .icon-link-list li + li:before {
    content: '';
    position: absolute;
    top: -100px;
    left: 0;
    bottom: -100px;
    width: 1px;
    background: #979797;
  }
  .banner .icon-link-list li img {
    max-width: 36px;
  }
  .banner .icon-link-list li strong {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .banner .icon-link-list li {
    min-width: 150px;
  }
  .banner .icon-link-list li img {
    max-width: 46px;
  }
  .banner .icon-link-list li strong {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .banner .icon-link-list li {
    min-width: 190px;
  }
}

.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}

.acf-map {
  width: 100%;
  height: 300px;
}
@media (min-width: 600px) {
  .acf-map {
    height: 320px;
  }
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

/** page heading styles */
.page-heading {
  padding: 30px 0;
  text-align: center;
}
.page-heading h2 {
  margin: 0 0 20px;
}
.page-heading .content {
  overflow: hidden;
  margin-bottom: -1em;
}
.page-heading .button-holder {
  padding-top: 20px;
}
.page-heading .contact-info {
  padding: 20px 0 0;
}
.page-heading .contact-info .title {
  display: block;
  padding-bottom: 5px;
}
@media (min-width: 600px) {
  .page-heading {
    padding: 40px 0;
  }
  .page-heading .button-holder {
    padding-top: 30px;
  }
  .page-heading .contact-info {
    padding-top: 30px;
  }
}
@media (min-width: 768px) {
  .page-heading {
    padding: 60px 0;
  }
  .page-heading h1 {
    margin-bottom: 30px;
  }
  .page-heading .button-holder {
    padding-top: 40px;
  }
}

/** link block styles */
.link-section {
  padding: 30px 0;
  text-align: center;
}
.link-section h3 {
  margin-bottom: 20px;
}
@media (min-width: 600px) {
  .link-section {
    padding: 40px 0;
  }
}
@media (min-width: 768px) {
  .link-section {
    padding: 60px 0;
  }
}

form .button,
.button {
  display: inline-block;
  vertical-align: top;
  border: 2px solid #8a4a97;
  padding: 10px 15px;
  border-radius: 0 0 10px 0;
  font: 700 14px/1.2307 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
}
form .button:hover,
.button:hover {
  background: #8a4a97;
  color: #fff;
}
form .button.disabled,
.button.disabled {
  background: #4a4a4a;
  border-color: #4a4a4a;
  color: #fff;
  pointer-events: none;
  cursor: not-allowed;
}
@media (min-width: 600px) {
  form .button,
  .button {
    padding: 14px 20px;
  }
}

/** Image Block Styles */
.image-section {
  position: relative;
  overflow: hidden;
}
.image-section .bg-img {
  z-index: -1;
}
.image-section .bg-img, .image-section .bg-img:after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.image-section .bg-img:after {
  content: '';
  z-index: 1;
  background: rgba(138, 74, 151, 0.15);
}
.image-section .overlay {
  height: 360px;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.image-section .overlay .holder {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.image-section .overlay .md {
  max-width: 300px;
}
@media (min-width: 600px) {
  .image-section .overlay {
    height: 460px;
  }
  .image-section .overlay .md {
    max-width: 420px;
  }
}
@media (min-width: 992px) {
  .image-section .overlay {
    height: 560px;
  }
  .image-section .overlay .md {
    max-width: 52%;
  }
}
@media (min-width: 1200px) {
  .image-section .overlay {
    height: 660px;
  }
  .image-section .overlay .md {
    max-width: 680px;
  }
}
.image-section .title {
  display: block;
  color: #fff;
  line-height: 1.63636;
  margin: 0;
  padding: 20px 0;
}

/** clients section styles */
.clients-section {
  background: #ededed;
  text-align: center;
  padding: 30px 0;
}
.clients-section h2 {
  margin-bottom: 20px;
  color: #8a4a97;
}
.clients-section .description {
  overflow: hidden;
  padding-bottom: 20px;
}
@media (min-width: 600px) {
  .clients-section {
    padding: 40px 0;
  }
}
@media (min-width: 768px) {
  .clients-section {
    padding: 60px 0;
  }
  .clients-section h2 {
    margin-bottom: 30px;
  }
  .clients-section .description {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .clients-section {
    padding: 80px 0;
  }
  .clients-section .description {
    padding-bottom: 40px;
  }
}

.clients-logo {
  text-align: center;
}
.clients-logo li {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  padding: 15px;
}
.clients-logo img {
  max-height: 60px;
}
@media (min-width: 600px) {
  .clients-logo li {
    width: 30%;
    padding: 20px;
  }
}
@media (min-width: 992px) {
  .clients-logo li {
    width: 23%;
  }
  .clients-logo img {
    max-height: 90px;
    max-width: 50%;
  }
}

/** page content styles */
.page-content {
  padding: 30px 0;
}
.single-case .page-content {
  padding-bottom: 20px;
}
.page-content blockquote {
  padding: 0 0 0 20px;
  margin: 0 0 30px;
  border-left: 5px solid #8a4a97;
  font: 18px/1.5 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  color: #8a4a97;
}
.page-content blockquote p {
  margin: 0;
}
@media (min-width: 600px) {
  .page-content {
    padding: 40px 0;
    font-size: 20px;
    border-left-width: 10px;
  }
  .single-case .page-content {
    padding-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .page-content {
    padding: 50px 0;
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .page-content {
    padding: 60px 0;
  }
}
@media (min-width: 1200px) {
  .page-content {
    padding: 70px 0;
  }
}

.content-center {
  text-align: center;
}

.content-column {
  padding-bottom: 20px;
}
.content-column .container {
  padding: 0;
  max-width: 100% !important;
}
.content-column .page-heading {
  text-align: left;
}
@media (min-width: 768px) {
  .content-column {
    padding-bottom: 0;
  }
}

.widget .people-widget + .people-widget {
  padding-top: 30px;
}

.people-widget {
  overflow: hidden;
  font-size: 15px;
  line-height: 1.375;
  max-width: 264px;
}
.people-widget .people-thumb {
  padding-bottom: 20px;
  max-width: 180px;
}
.people-widget .people-thumb img {
  width: 100%;
  max-width: 246px;
  max-height: 246px;
  border-radius: 100%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .people-widget .people-thumb {
    max-width: 100%;
  }
}
.people-widget .people-info {
  overflow: hidden;
}
.people-widget .name {
  display: block;
  color: #8a4a97;
  font: 700 18px/1.325 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
}
.people-widget .job-post {
  display: block;
  padding-bottom: 1em;
}
.people-widget .text-row {
  display: block;
  padding-top: 2px;
}
.people-widget .text-row .btr {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  min-width: 22px;
  text-align: center;
}
.people-widget .text-row.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .people-widget a[href^=tel], .people-widget a[href^=skype], .people-widget a[href^=sms] {
    color: #4a4a4a;
  }
}
.people-widget .desc {
  padding-top: 10px;
  font-size: 15px;
  clear: both;
  margin-top: 30px;
  border-top: 1px solid #9b9b9b;
}

.company-image {
  margin-bottom: 20px;
  max-width: 264px;
  margin: 0 auto;
}
.company-image img {
  width: 100%;
  height: auto;
}
.company-image .caption {
  display: block;
  padding: 5px 10px;
  background: #f5f5f5;
  font-size: 14px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .company-image {
    max-width: 100%;
  }
}

/** instagram styles */
.instagram-block {
  text-align: center;
  padding: 30px 0;
}
.instagram-block h3 {
  margin-bottom: 20px;
}
.instagram-block .instagram-feed {
  margin: 0 -20px;
}
@media (min-width: 600px) {
  .instagram-block .instagram-feed {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .instagram-block {
    padding: 50px 0;
  }
  .instagram-block h3 {
    margin-bottom: 40px;
  }
  .instagram-block .instagram-feed {
    padding-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .instagram-block {
    padding: 70px 0;
  }
  .instagram-block h3 {
    margin-bottom: 50px;
  }
  .instagram-block .instagram-feed {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .instagram-block {
    padding: 100px 0;
  }
  .instagram-block h3 {
    margin-bottom: 60px;
  }
  .instagram-block .instagram-feed {
    padding-bottom: 30px;
  }
}

/** image gallery styles */
.image-gallery {
  padding: 20px 0 30px;
}
.single-case .image-gallery {
  padding-top: 0;
}
@media (min-width: 768px) {
  .image-gallery {
    padding: 40px 0 50px;
  }
}
@media (min-width: 992px) {
  .image-gallery {
    padding: 60px 0 80px;
  }
}
@media (min-width: 1200px) {
  .image-gallery {
    padding: 80px 0 100px;
  }
}

.image-slideshow {
  overflow: hidden;
}
.image-slideshow .slideshow-holder {
  position: relative;
}
.image-slideshow .slideshow-holder .slick-arrow {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  text-align: center;
  z-index: 2;
  background: #8a4a97;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
}
.image-slideshow .slideshow-holder .slick-arrow:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.image-slideshow .slideshow-holder .slick-arrow i {
  display: inline-block;
  vertical-align: middle;
}
.image-slideshow .slideshow-holder .slick-arrow:hover {
  background: #7a4286;
}
@media (min-width: 600px) {
  .image-slideshow .slideshow-holder .slick-arrow {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    font-size: 16px;
  }
}
.image-slideshow .slideshow-holder .slide-prev {
  left: 0;
}
.image-slideshow .slideshow-holder .slide-next {
  right: 0;
}
.image-slideshow .slideshow img {
  width: 100%;
  outline: none !important;
}
.image-slideshow .slider-pagination {
  padding-top: 5px;
  margin: 0 -5px;
}
.image-slideshow .slider-pagination img {
  padding: 5px;
  outline: none !important;
  opacity: 1;
  transition: opacity 200ms;
}
.image-slideshow .slider-pagination img:hover, .image-slideshow .slider-pagination img.slick-current {
  opacity: .8;
}
@media (min-width: 600px) {
  .image-slideshow .slider-pagination {
    padding-top: 15px;
  }
}
@media (min-width: 768px) {
  .image-slideshow .slider-pagination {
    padding-top: 25px;
  }
}

/** experiences page styles */
.page-template-experiences .content-column .page-heading {
  text-align: center;
}
@media (min-width: 480px) {
  .page-template-experiences .content-column .page-heading {
    text-align: left;
  }
}

.article-wrapper {
  padding: 20px 0;
}
@media (min-width: 600px) {
  .article-wrapper {
    padding: 20px 0 40px;
  }
}
@media (min-width: 768px) {
  .article-wrapper {
    padding: 25px 0 80px;
  }
  .taxonomy-page .article-wrapper {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .article-wrapper {
    padding: 30px 0 100px;
  }
}

.experience-article {
  overflow: hidden;
  padding-bottom: 20px;
  font-size: 16px;
}
.experience-article + .experience-article {
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
}
@media (min-width: 480px) {
  .experience-article {
    padding-bottom: 30px;
  }
  .experience-article + .experience-article {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .experience-article {
    font-size: 18px;
  }
}
.experience-article .thumbnail {
  max-width: 264px;
  margin: 0 auto 10px;
}
.experience-article .thumbnail a {
  display: block;
  opacity: 1;
  transition: opacity 200ms linear;
}
.experience-article .thumbnail a:hover {
  opacity: .85;
}
@media (min-width: 480px) {
  .experience-article .thumbnail {
    float: left;
    width: 35.892%;
    margin: 0;
  }
}
.experience-article .desc {
  overflow: hidden;
  text-align: center;
}
@media (min-width: 480px) {
  .experience-article .desc {
    padding-left: 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .experience-article .desc {
    padding-left: 30px;
  }
}
.experience-article h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 5px;
}
.experience-article p {
  margin: 0;
}

.left-sidebar {
  padding: 30px 0 0;
}
@media (min-width: 768px) {
  .left-sidebar {
    padding: 50px 15px 50px 0;
  }
}
@media (min-width: 992px) {
  .left-sidebar {
    padding-right: 0;
  }
}

.type-list,
.type-list ul {
  text-align: center;
}
.type-list li {
  padding: 5px 0;
  font: 16px/1.325 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  display: inline-block;
  vertical-align: top;
}
.type-list .active > a {
  background: #8a4a97;
  color: #fff;
  font-weight: 700;
}
.type-list a {
  display: block;
  padding: 5px 10px;
  border: 1px solid #8a4a97;
  color: #8a4a97;
}
.type-list a:hover {
  color: #fff;
  background: #8a4a97;
}
@media (min-width: 768px) {
  .type-list a {
    display: inline;
    border: none;
    padding: 0;
    color: #4a4a4a;
  }
  .type-list a:hover {
    background: transparent;
    color: #8a4a97;
  }
}
@media (min-width: 480px) {
  .type-list {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .type-list li {
    font-size: 18px;
    padding: 14px 0;
    display: block;
  }
  .type-list li + li {
    border-top: 1px solid #979797;
  }
  .type-list .active a {
    background: transparent;
    color: #8a4a97;
  }
}
@media (min-width: 992px) {
  .type-list li {
    font-size: 20px;
    padding: 16px 0;
  }
}
@media (min-width: 1200px) {
  .type-list li {
    font-size: 24px;
    padding: 20px 0;
  }
}

/** case page & articles styles */
.case-wrapper {
  padding: 0 0 20px;
}
@media (min-width: 600px) {
  .case-wrapper {
    padding: 0 0 40px;
  }
}
@media (min-width: 768px) {
  .case-wrapper {
    padding: 0 0 80px;
    margin: 0 -20px;
  }
}
@media (min-width: 992px) {
  .case-wrapper {
    padding: 0 0 100px;
  }
}

.case-article {
  padding-top: 20px;
  max-width: 500px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .case-article {
    padding-top: 40px;
    display: inline-block;
    vertical-align: top;
    width: 48%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 992px) {
  .case-article {
    padding-top: 50px;
  }
}
@media (min-width: 1200px) {
  .case-article {
    padding-top: 70px;
  }
}
.case-article .thumbnail {
  position: relative;
  margin-bottom: 10px;
}
.case-article .thumbnail a {
  display: block;
  position: relative;
  transition: opacity 200ms linear;
}
.case-article .thumbnail a:hover {
  opacity: 0.9;
}
.case-article .thumbnail img {
  margin-bottom: 10px;
}
@media (min-width: 600px) {
  .case-article .thumbnail {
    margin-bottom: 20px;
  }
  .case-article .thumbnail img {
    margin-bottom: 0;
  }
  .case-article .thumbnail h3 {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 22px;
    padding: 10px 20px;
    display: block;
    background: rgba(138, 74, 151, 0.8);
    color: #fff;
  }
}

.loadmore-section {
  text-align: center;
  padding: 30px 0;
}
@media (min-width: 768px) {
  .loadmore-section {
    padding: 0 0 60px;
  }
}

/** contact form styles */
.contact-form-section {
  padding: 0 0 40px;
}
.contact-form-section h2 {
  text-align: center;
  margin: 0 0 40px;
}
@media (min-width: 992px) {
  .contact-form-section {
    padding-bottom: 70px;
  }
}
@media (min-width: 1200px) {
  .contact-form-section {
    padding-bottom: 100px;
  }
}

.gform_wrapper .validation_error {
  display: block;
  color: #f00;
  border: 1px solid #f00;
  padding: 15px;
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
}
.gform_wrapper .gfield {
  padding-bottom: 20px;
}
.gform_wrapper .gfield .gfield_label {
  font-size: 16px;
  font-weight: 500;
  color: #8a4a97;
}
.gform_wrapper .gfield input {
  height: 48px;
}
.gform_wrapper .gfield input,
.gform_wrapper .gfield select,
.gform_wrapper .gfield textarea {
  width: 100%;
}
.gform_wrapper .gfield input:focus,
.gform_wrapper .gfield select:focus,
.gform_wrapper .gfield textarea:focus {
  outline: none;
}
.gform_wrapper .gfield select {
  height: 48px;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0.5em 1em;
  padding-right: 2.65em;
  border-radius: 0;
  -webkit-font-smoothing: inherit;
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" preserveAspectRatio="none"><polygon points="0,0 2,0 1,2" fill="#4a4a4a" /></svg>');
  background-size: 0.65em 0.4225em;
  background-position: calc(100% - 1em) center;
  background-repeat: no-repeat;
  box-shadow: none;
  cursor: pointer;
  transition: .2s;
}
.gform_wrapper .gfield select:focus {
  outline: 0;
}
.gform_wrapper .gfield select:disabled {
  opacity: .7;
  cursor: default;
}
@media (min-width: 768px) {
  .gform_wrapper .gfield {
    padding-bottom: 30px;
  }
  .gform_wrapper .gfield .gfield_label {
    font-size: 18px;
  }
}
.gform_wrapper .ginput_container {
  padding: 10px 0 0;
}
.gform_wrapper .gform_validation_container {
  display: none !important;
}
.gform_wrapper .validation_message {
  color: #f00;
  font-size: 14px;
  display: block;
  padding: 10px 0 0;
}
.gform_wrapper .button {
  min-width: 140px;
  font-size: 16px;
  color: #8a4a97;
}

.contact-person-section {
  padding: 20px 0;
}
@media (min-width: 768px) {
  .contact-person-section {
    padding: 40px 0;
  }
}

.contact-person-box {
  background: #ededed;
  padding: 30px 20px;
  position: relative;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}
@media (min-width: 768px) {
  .contact-person-box {
    margin-bottom: 30px;
  }
}
.contact-person-box .icon {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 15px;
}
.contact-person-box h3 {
  color: #8a4a97;
}
.contact-person-box .details {
  overflow: hidden;
}
.contact-person-box .thumb {
  width: 100px;
  margin: 0 auto 20px;
  border-radius: 100%;
  overflow: hidden;
}
.contact-person-box .thumb img {
  width: 100%;
  height: auto;
}
.contact-person-box .people-info {
  overflow: hidden;
  padding: 20px 0 0;
}
.contact-person-box .people-info .name {
  display: block;
  font: 700 16px/1.325 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  color: #8a4a97;
  padding-bottom: 8px;
}
.contact-person-box .people-info span {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
}
.contact-person-box .people-info span i {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  padding-top: 2px;
  width: 22px;
  text-align: center;
}
.contact-person-box .people-info span a {
  color: #192726;
}
@media (min-width: 600px) {
  .contact-person-box {
    padding: 50px 40px 40px;
    text-align: left;
  }
  .contact-person-box .icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .contact-person-box .thumb {
    float: left;
  }
  .contact-person-box h3 {
    padding-right: 80px;
    min-height: 60px;
  }
  .contact-person-box .people-info .desc {
    overflow: hidden;
    padding: 10px 0 0 20px;
  }
}
@media (min-width: 768px) {
  .contact-person-box {
    font-size: 16px;
  }
  .contact-person-box .icon {
    top: 40px;
    right: 40px;
  }
  .contact-person-box .thumb {
    width: 120px;
    margin: 0;
  }
  .contact-person-box .people-info .desc {
    padding: 18px 0 0 20px;
  }
}
@media (min-width: 992px) {
  .contact-person-box .thumb {
    width: 120px;
  }
  .contact-person-box .details {
    min-height: 150px;
  }
}
@media (min-width: 1200px) {
  .contact-person-box .thumb {
    width: 140px;
  }
  .contact-person-box .people-info .desc {
    overflow: hidden;
    padding: 25px 0 0 25px;
  }
  .contact-person-box .people-info .name {
    font-size: 18px;
  }
  .contact-person-box .people-info span {
    font-size: 16px;
  }
}

/** article columns styles */
.article-columns {
  padding: 30px 0 0;
}
.article-columns h2 {
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
.article-columns .article-column {
  text-align: center;
  max-width: 480px;
  margin: 0 auto 10px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .article-columns {
    padding-top: 60px;
  }
  .article-columns .article-column {
    margin: 0;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .article-columns .article-column {
    padding: 0 5%;
  }
}
@media (min-width: 1200px) {
  .article-columns {
    padding: 110px 0 0;
  }
  .article-columns h2 {
    margin-bottom: 60px;
  }
}

.sidebar .quote-image {
  padding: 0 0 10px;
  max-width: 120px;
  margin: 0 auto;
}
.sidebar .quote {
  display: block;
  overflow: hidden;
  font: 18px/1.5 "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  padding-top: 10px;
  margin-bottom: 20px;
  position: relative;
  color: #8a4a97;
}
.sidebar .quote:before {
  content: '“';
  font-size: 80px;
  line-height: 1;
  display: inline-block;
  vertical-align: top;
  height: 33px;
  position: relative;
  top: -10px;
  padding-right: 6px;
}
.sidebar .sidebar-quote {
  max-width: 380px;
  margin: 0 auto;
  padding-bottom: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .sidebar {
    padding-left: 20px;
  }
  .sidebar .quote-image {
    margin: 0;
  }
  .sidebar .sidebar-quote {
    text-align: left;
    max-width: none;
  }
}
@media (min-width: 992px) {
  .sidebar {
    padding-left: 40px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#wrapper {
  overflow: hidden;
  padding-top: 65px;
}
@media (min-width: 768px) {
  #wrapper {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  #wrapper {
    padding-top: 100px;
  }
}

.bg-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img img {
  opacity: 0;
  visibility: hidden;
}

.colorPurple {
  color: #8a4a97;
}

.quote p {
  color: #8a4a97;
  font-size: 110%;
  font-style: oblique;
}

.container.md {
  max-width: 680px;
}

#header {
  padding: 12px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  min-width: 320px;
  background: #fff;
  z-index: 999;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}
@media (min-width: 992px) {
  #header {
    padding: 20px 0;
  }
}

.logo {
  float: left;
  width: 120px;
}
.logo a {
  display: block;
}
@media (min-width: 768px) {
  .logo {
    width: 140px;
  }
}
@media (min-width: 992px) {
  .logo {
    width: 180px;
  }
}

.navbar {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  background: #4a4a4a;
  opacity: 0;
  visibility: hidden;
}
.menu-active .navbar {
  opacity: 1;
  visibility: visible;
}
.navbar .holder {
  text-align: center;
  padding: 30px 0;
}
@media (min-width: 992px) {
  .navbar {
    float: right;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    background-color: transparent;
    visibility: visible;
    opacity: 1;
  }
  .navbar .holder {
    text-align: left;
    padding: 0;
  }
}

.header-info {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  padding: 20px 0 10px 20px;
  position: relative;
  border-top: 1px solid #666666;
  max-width: 240px;
  margin: 0 auto;
}
.header-info a {
  color: #fff;
}
.header-info a:hover {
  color: #d9d9d9;
}
@media (min-width: 992px) {
  .header-info {
    display: inline-block;
    vertical-align: middle;
    border-top: none;
    border-left: 1px solid #4a4a4a;
    margin-left: 20px;
    padding-top: 10px;
    line-height: 1.2;
  }
  .header-info a {
    color: #8a4a97;
  }
}
@media (min-width: 1200px) {
  .header-info {
    font-size: 15px;
  }
}

#footer {
  background: #8a4a97;
}

.footer-top {
  padding: 40px 0 15px;
  color: #fff;
  font-size: 15px;
  line-height: 1.2;
}
.footer-top a {
  color: #fff;
}
.footer-top a:hover {
  color: #d9d9d9;
}
@media (min-width: 768px) {
  .footer-top {
    padding: 40px 0 15px;
  }
}
@media (min-width: 992px) {
  .footer-top {
    padding: 50px 0;
  }
}
.footer-logo {
  padding-bottom: 20px;
  clear: both;
  width: 150px;
  margin: 0 auto;
}
.footer-logo a {
  display: block;
}
@media (min-width: 600px) {
  .footer-logo {
    padding: 0 30px 10px 0;
    margin: 0;
    float: left;
    clear: none;
  }
}
@media (min-width: 768px) {
  .footer-logo {
    width: 25%;
    padding-right: 10px;
  }
}
@media (min-width: 992px) {
  .footer-logo {
    width: 22%;
    padding-top: 10px;
  }
}
.footer-contact-info {
  clear: both;
  text-align: center;
}
.footer-contact-info .address,
.footer-contact-info .method {
  padding-bottom: 20px;
}
.footer-contact-info .address strong,
.footer-contact-info .method strong {
  font-family: "Roboto Slab", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
}
.footer-contact-info address {
  font-style: normal;
}
.footer-contact-info .contact-row {
  padding-bottom: 5px;
}
@media (min-width: 600px) {
  .footer-contact-info {
    width: 38%;
    float: left;
    clear: none;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .footer-contact-info {
    width: 56%;
  }
  .footer-contact-info .address,
  .footer-contact-info .method {
    float: left;
    width: 50%;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .footer-contact-info {
    width: 42%;
    padding-top: 10px;
  }
}
.footer-menu {
  text-align: center;
}
.footer-menu li {
  padding-bottom: 5px;
}
.footer-menu .active a {
  font-weight: 700;
}
@media (min-width: 600px) {
  .footer-menu {
    float: right;
    width: 120px;
    text-align: left;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .footer-menu {
    padding-top: 8px;
  }
  .footer-menu li {
    padding-bottom: 20px;
  }
}
.footer-bottom {
  padding: 35px 0;
  border-top: 1px solid #fff;
  font-size: 13px;
  color: #fff;
}

.social-links {
  width: 100%;
  clear: both;
  text-align: center;
  padding: 20px 0 0;
}
.social-links li {
  padding: 0 5px 8px;
  display: inline-block;
  vertical-align: top;
}
.social-links li i {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 100%;
  overflow: hidden;
  color: #8a4a97;
  text-align: center;
  white-space: nowrap;
  transition: background 200ms linear;
}
.social-links li i:before, .social-links li i:after {
  display: inline-block;
  vertical-align: middle;
}
.social-links li i:after {
  content: '';
  height: 100%;
}
.social-links li span {
  display: none;
}
.social-links li a:hover i {
  background: #d9d9d9;
}
@media (min-width: 600px) {
  .social-links li {
    padding: 0 10px 8px;
  }
  .social-links li span {
    display: inline-block;
    vertical-align: middle;
    padding-left: 8px;
  }
}
@media (min-width: 992px) {
  .social-links {
    float: right;
    width: 18%;
    clear: none;
    text-align: left;
    padding: 0;
  }
  .social-links li {
    display: block;
    padding: 0 0 8px;
  }
}

.payment-icons {
  text-align: center;
  padding-bottom: 20px;
}
.payment-icons li {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
  padding-bottom: 10px;
}
.payment-icons img {
  max-height: 30px;
}
@media (min-width: 992px) {
  .payment-icons {
    float: right;
    text-align: right;
    padding-bottom: 0;
  }
}

.copyright {
  display: block;
  overflow: hidden;
  padding: 10px 0;
  text-align: center;
}
@media (min-width: 992px) {
  .copyright {
    text-align: left;
  }
}


