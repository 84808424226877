/*!
Theme Name: Weway
Theme URI: http://pigment.se/
Author: Pigment AB
Author URI: http://pigment.se/
Version: 1.0
*/

@import "vendors/index";
@import "base/index";
@import "components/index";
@import "layout/index";
