// add font face
@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-MediumItalic.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('assets/fonts/roboto/Roboto-MediumItalic.woff') format('woff'),
    url('assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype'),
    url('assets/fonts/roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-BoldItalic.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/roboto/Roboto-BoldItalic.woff') format('woff'),
    url('assets/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'),
    url('assets/fonts/roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
    url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype'),
    url('assets/fonts/roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('assets/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
    url('assets/fonts/roboto/Roboto-Bold.ttf') format('truetype'),
    url('assets/fonts/roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('assets/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
    url('assets/fonts/roboto/Roboto-Medium.ttf') format('truetype'),
    url('assets/fonts/roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('assets/fonts/roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto/Roboto-Italic.woff2') format('woff2'),
    url('assets/fonts/roboto/Roboto-Italic.woff') format('woff'),
    url('assets/fonts/roboto/Roboto-Italic.ttf') format('truetype'),
    url('assets/fonts/roboto/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('assets/fonts/roboto-slab/RobotoSlab-Bold.eot');
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
    url('assets/fonts/roboto-slab/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto-slab/RobotoSlab-Bold.woff2') format('woff2'),
    url('assets/fonts/roboto-slab/RobotoSlab-Bold.woff') format('woff'),
    url('assets/fonts/roboto-slab/RobotoSlab-Bold.ttf') format('truetype'),
    url('assets/fonts/roboto-slab/RobotoSlab-Bold.svg#RobotoSlab-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('assets/fonts/roboto-slab/RobotoSlab-Regular.eot');
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
    url('assets/fonts/roboto-slab/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/roboto-slab/RobotoSlab-Regular.woff2') format('woff2'),
    url('assets/fonts/roboto-slab/RobotoSlab-Regular.woff') format('woff'),
    url('assets/fonts/roboto-slab/RobotoSlab-Regular.ttf') format('truetype'),
    url('assets/fonts/roboto-slab/RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
