.sidebar {
  .quote-image {
    padding: 0 0 10px;
    max-width: 120px;
    margin: 0 auto;
  }

  .quote {
    display: block;
    overflow: hidden;
    font: 18px/1.5 $roboto-slab;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    color: #8a4a97;

    &:before {
      content: '“';
      font-size: 80px;
      line-height: 1;
      display: inline-block;
      vertical-align: top;
      height: 33px;
      position: relative;
      top: -10px;
      padding-right: 6px;
    }
  }

  .sidebar-quote {
    max-width: 380px;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
  }

  @include media('>=tablet') {
    padding-left: 20px;

    .quote-image {
      margin: 0;
    }

    .sidebar-quote {
      text-align: left;
      max-width: none;
    }
  }

  @include media('>=desktop') {
    padding-left: 40px;
  }
}
