// Reset border, margin and padding for form and fieldset
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

// Reset to avoid WebKit bug
button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"]{
  -webkit-appearance:none;
  -webkit-border-radius:0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input[type="search"] {
  box-sizing: border-box;
}

// General reset for textarea
textarea {
  resize: vertical;
  vertical-align: top;
}

// Reset for select in WebKit browser
select {
  -webkit-border-radius: 0;
}

// Form inputs and select style
input,
textarea,
select {
  font: 100% $base-font-family;
  color: $base-form-text-color;
  vertical-align: middle;
}

// General reset for input types
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea{
  margin: 0;
  -webkit-appearance:none;
  -webkit-border-radius:0;

  // Form inputs style reset
  border-radius: $form-input-border-radius;
  border: $form-input-border-width solid $form-element-border-color;
  padding: $form-element-padding;
  color: $form-element-color;

  @include placeholder {
    color: $placeholder-color;
  }

  &:focus {
    border-color: $form-element-focus-border-color;
  }
}
