.acf-map {
  width: 100%;
  // height: 340px;
  height: 300px;

  @include media('>=phone') {
    // height: 380px;
    height: 320px;
  }
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}
