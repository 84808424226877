/** clients section styles */
.clients-section {
  background: #ededed;
  text-align: center;
  padding: 30px 0;

  h2 {
    margin-bottom: 20px;
    color: $primary-color;
  }

  .description {
    overflow: hidden;
    padding-bottom: 20px;
  }

  @include media('>=phone') {
    padding: 40px 0;
  }

  @include media('>=tablet') {
    padding: 60px 0;

    h2 {
      margin-bottom: 30px;
    }

    .description {
      padding-bottom: 30px;
    }
  }

  @include media('>=desktop') {
    padding: 80px 0;

    .description {
      padding-bottom: 40px;
    }
  }
}

.clients-logo {
  @extend %listreset;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;
    width: 120px;
    padding: 15px;
  }

  img {
    max-height: 60px;
  }

  @include media('>=phone') {
    li {
      width: 30%;
      padding: 20px;
    }
  }

  @include media('>=desktop') {
    li {
      width: 23%;
    }

    img {
      // max-height: none;
      max-height: 90px;
      max-width: 50%;
    }
  }
}
