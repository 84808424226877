// Set color, background, font and other property in body
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-font-variant-ligatures: no-common-ligatures;

  color: $base-text-color;
  background: $base-background-color;
  font: #{$base-font-size-sm}/#{$base-line-height} $base-font-family;
  min-width: $base-min-width;

  @media ( min-width: 768px ) {
    font-size: $base-font-size;
  }
}

// Set default link color and link hover color for anchor tag
a {
  color: $base-link-color;
  transition: background 200ms, color 200ms;
  text-decoration: none;

  &:hover {
    color: $base-link-hover-color;
  }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
}

// Hide tel links in desktop
@media only screen and (min-width:1025px) {
  a[href^=tel],a[href^=skype], a[href^=sms] {
    cursor:default;
    pointer-events:none;
  }
}

/*IE 8 WARNING*/
.alert-warning {
  position: fixed;
  z-index: 9999999;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding-top: 10px;
  font-size: 20px;
  text-align: center;
  color: #fff;
  background: #9c2213;

  a {
    font-weight: 800;
    text-decoration: underline;
    color: #fff;
  }
}
