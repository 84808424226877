/** Image Block Styles */
.image-section {
  position: relative;
  overflow: hidden;

  .bg-img {
    z-index: -1;

    &,
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    &:after {
      content: '';
      z-index: 1;
      background: rgba($primary-color, .15);
    }
  }

  .overlay {
    height: 360px;
    display: table;
    table-layout: fixed;
    width: 100%;

    .holder {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    .md {
      max-width: 300px;
    }

    @include media('>=phone') {
      height: 460px;

      .md {
        max-width: 420px;
      }
    }

    @include media('>=desktop') {
      height: 560px;

      .md {
        max-width: 52%;
      }
    }

    @include media('>=widescreen') {
      height: 660px;

      .md {
        max-width: 680px;
      }
    }
  }

  .title {
    display: block;
    color: $white;
    line-height: 1.63636;
    margin: 0;
    padding: 20px 0;
  }
}
