/** page content styles */
.page-content {
  padding: 30px 0;

  .single-case & {
    padding-bottom: 20px;
  }

  blockquote {
    padding: 0 0 0 20px;
    margin: 0 0 30px;
    border-left: 5px solid $primary-color;
    font: 18px/1.5 $roboto-slab;
    color: $primary-color;

    p { margin: 0; }
  }

  @include media('>=phone') {
    padding: 40px 0;
    font-size: 20px;
    border-left-width: 10px;

    .single-case & {
      padding-bottom: 30px;
    }
  }

  @include media('>=tablet') {
    padding: 50px 0;
    font-size: 22px;
  }

  @include media('>=desktop') {
    padding: 60px 0;
  }

  @include media('>=widescreen') {
    padding: 70px 0;
  }
}

.content-center {
  text-align: center;
}

.content-column {
  padding-bottom: 20px;

  .container {
    padding: 0;
    max-width: 100% !important;
  }

  .page-heading {
    text-align: left;
  }

  @include media('>=tablet') {
    padding-bottom: 0;
  }
}

.widget {
  .people-widget + .people-widget {
    padding-top: 30px;
  }
}

.people-widget {
  overflow: hidden;
  font-size: 15px;
  line-height: 1.375;
  max-width: 264px;

  .people {
    &-thumb {
      padding-bottom: 20px;
      max-width: 180px;

      img {
        width: 100%;
        max-width: 246px;
        max-height: 246px;
        border-radius: 100%;
        overflow: hidden;
      }

      @include media('>=tablet') {
        max-width: 100%;
      }
    }

    &-info {
      overflow: hidden;
    }
  }

  .name {
    display: block;
    color: $primary-color;
    font: 700 18px/1.325 $roboto-slab;
  }

  .job-post {
    display: block;
    padding-bottom: 1em;
  }

  .text-row {
    display: block;
    padding-top: 2px;

    .btr {
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      min-width: 22px;
      text-align: center;
    }

    &.email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a[href^=tel],a[href^=skype], a[href^=sms] {
    @include media('>=tablet') {
      color: $gray;
    }
  }

  .desc {
    padding-top: 10px;
    font-size: 15px;
    clear: both;
    margin-top: 30px;
    border-top: 1px solid #9b9b9b;
  }
}

.company-image {
  margin-bottom: 20px;
  max-width: 264px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  .caption {
    display: block;
    padding: 5px 10px;
    background: #f5f5f5;
    font-size: 14px;
    line-height: 1.5;
  }

  @include media('>=tablet') {
    max-width: 100%;
  }
}
