/** banner styles */
.banner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 340px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($primary-color, .24);
    z-index: 1;
  }

  &.category-banner {
    height: 180px;

    h1 {
      margin: 0;
    }

    .banner-overlay {
      padding: 20px 0;
    }
  }

  @include media('>=phone') {
    height: 440px;

    &.category-banner {
      height: 240px;
    }
  }

  @include media('>=tablet') {
    height: 580px;

    &.category-banner {
      height: 300px;
    }
  }

  @include media('>=desktop') {
    height: 620px;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  &-overlay {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    white-space: normal;
    font-size: 16px;
    color: $white;
    padding: 30px 0;
    position: relative;
    z-index: 2;
    text-align: center;

    @include media('>=phone') {
      font-size: 18px;
      padding: 30px 0;
      text-align: left;
    }

    @include media('>=tablet') {
      font-size: 20px;
    }
  }

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .holder {
    max-width: 400px;
    margin: 0 auto;

    @include media('>=phone') {
      max-width: 450px;
      margin: 0;
    }

    @include media('>=tablet') {
      max-width: 550px;
    }

    @include media('>=desktop') {
      max-width: 600px;
    }

    @include media('>=widescreen') {
      max-width: 650px;
    }
  }

  h1 {
    font-weight: 700;
    margin: 0 0 15px;
  }

  &.have-icons {
    height: 460px;

    .banner-overlay {
      padding-bottom: 120px;
    }

    @include media('>=phone') {
      height: 540px;

      .banner-overlay {
        padding-bottom: 170px;
      }
    }

    @include media('>=tablet') {
      height: 640px;

      .banner-overlay {
        padding-bottom: 185px;
      }
    }

    @include media('>=desktop') {
      height: 740px;
    }
  }

  &-link-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px 0;
    background: rgba($white, .78);
    white-space: normal;
    z-index: 2;

    @include media('>=phone') {
      padding: 20px 0;
    }
  }

  .icon-link-list {
    @extend %listreset;
    text-align: center;
    overflow: hidden;
    margin: 0 -10px;

    li {
      display: inline-block;
      vertical-align: bottom;
      position: relative;
      padding: 0 6px;
      min-width: 22%;

      a {
        display: block;
      }

      img {
        max-width: 24px;
      }

      strong {
        display: block;
        font: 700 12px/1.2 $roboto-slab;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        padding-top: 15px;
      }

      @include media('>=phone') {
        min-width: 120px;
        padding: 10px;

        & + li {
          &:before {
            content: '';
            position: absolute;
            top: -100px;
            left: 0;
            bottom: -100px;
            width: 1px;
            background: #979797;
          }
        }

        img {
          max-width: 36px;
        }

        strong {
          font-size: 14px;
        }
      }

      @include media('>=tablet') {
        min-width: 150px;

        img {
          max-width: 46px;
        }

        strong {
          font-size: 15px;
        }
      }

      @include media('>=widescreen') {
        min-width: 190px;
      }
    }
  }
}

.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}
