/** page heading styles */
.page-heading {
  padding: 30px 0;
  text-align: center;

  h2 {
    margin: 0 0 20px;
  }

  .content {
    overflow: hidden;
    margin-bottom: -1em;
  }

  .button-holder {
    padding-top: 20px;
  }

  .contact-info {
    padding: 20px 0 0;

    .title {
      display: block;
      padding-bottom: 5px;
    }
  }

  @include media('>=phone') {
    padding: 40px 0;

    .button-holder {
      padding-top: 30px;
    }

    .contact-info {
      padding-top: 30px;
    }
  }

  @include media('>=tablet') {
    padding: 60px 0;

    h1 {
      margin-bottom: 30px;
    }

    .button-holder {
      padding-top: 40px;
    }
  }
}
