// Typography
// Heading font, margin and color
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $headings-color;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  margin: 0 0 $heading-margin-bottom;
}

// Heading 1
h1, .h1 {
  font-size: $h1-font-size - 30;

  @include media('>=phone') {
    font-size: $h1-font-size - 20;
  }

  @include media('>=tablet') {
    font-size: $h1-font-size - 14
  }

  @include media('>=desktop') {
    font-size: $h1-font-size - 8;
  }

  @include media('>=widescreen') {
    font-size: $h1-font-size;
  }
}

// Heading 2
h2, .h2 {
  font-size: $h2-font-size - 24;

  @include media('>=phone') {
    font-size: $h2-font-size - 20;
  }

  @include media('>=tablet') {
    font-size: $h2-font-size - 14
  }

  @include media('>=desktop') {
    font-size: $h2-font-size - 8;
  }

  @include media('>=widescreen') {
    font-size: $h2-font-size;
  }
}

// Heading 3
h3, .h3 {
  font-size: $h3-font-size - 12;

  @include media('>=tablet') {
    font-size: $h3-font-size - 6;
  }

  @include media('>=widescreen') {
    font-size: $h3-font-size;
  }
}

// Heading 4
h4, .h4 {
  font-size: $h4-font-size;
}

// Heading 5
h5, .h5 {
  font-size: $h5-font-size;
}

// Heading 6
h6, .h6 {
  font-size: $h6-font-size;
}

// Set margin for all paragraphs
p {
  margin: 0 0 $paragraph-bottom-margin;
}

// Set default styles for mark tag
mark {
  background: $mark-background;
  color: $mark-color;
}

label {
    font-size: $base-label-font-size;
}

::selection {
    background: $base-selection-color;
    /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: $base-selection-color;
    /* Gecko Browsers */
}
