/** link block styles */
.link-section {
  padding: 30px 0;
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }

  @include media('>=phone') {
    padding: 40px 0;
  }

  @include media('>=tablet') {
    padding: 60px 0;
  }
}

form .button,
.button {
  display: inline-block;
  vertical-align: top;
  border: 2px solid $primary-color;
  padding: 10px 15px;
  border-radius: 0 0 10px 0;
  font: 700 14px/1.2307 $roboto-slab;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;

  &:hover {
    background: $primary-color;
    color: $white;
  }

  &.disabled {
    background: $gray;
    border-color: $gray;
    color: #fff;
    pointer-events: none;
    cursor: not-allowed;
  }

  @include media('>=phone') {
    padding: 14px 20px;
  }
}
