// footer styles
#footer {
  background: $primary-color;
}

.footer {
  &-top {
    @extend %clearfix;
    padding: 40px 0 15px;
    color: $white;
    font-size: 15px;
    line-height: 1.2;

    a {
      color: $white;

      &:hover {
        color: darken($white, 15);
      }
    }

    @include media('>=tablet') {
      padding: 40px 0 15px;
    }

    @include media('>=desktop') {
      padding: 50px 0;
    }
  }

  &-logo {
    padding-bottom: 20px;
    clear: both;
    width: 150px;
    margin: 0 auto;

    a {
      display: block;
    }

    @include media('>=phone') {
        padding: 0 30px 10px 0;
        margin: 0;
        float: left;
        clear: none;
    }

    @include media('>=tablet') {
      width: 25%;
      padding-right: 10px;
    }

    @include media('>=desktop') {
      width: 22%;
      padding-top: 10px;
    }
  }

  &-contact-info {
    clear: both;
    text-align: center;

    .address,
    .method {
      padding-bottom: 20px;

      strong {
        font-family: $roboto-slab;
      }
    }

    address {
      font-style: normal;
    }

    .contact-row {
      padding-bottom: 5px;
    }

    @include media('>=phone') {
      width: 38%;
      float: left;
      clear: none;
      text-align: left;
    }

    @include media('>=tablet') {
      width: 56%;

      .address,
      .method {
        float: left;
        width: 50%;
        padding: 0;
      }
    }

    @include media('>=desktop') {
      width: 42%;
      padding-top: 10px;
    }
  }

  &-menu {
    @extend %listreset;
    text-align: center;

    li {
      padding-bottom: 5px;
    }

    .active {
      a {
        font-weight: 700;
      }
    }

    @include media('>=phone') {
      float: right;
      width: 120px;
      text-align: left;
      padding-left: 15px;
    }

    @include media('>=desktop') {
      padding-top: 8px;

      li {
        padding-bottom: 20px;
      }
    }
  }

  &-bottom {
    @extend %clearfix;
    padding: 35px 0;
    border-top: 1px solid $white;
    font-size: 13px;
    color: $white;
  }
}

.social-links {
  @extend %listreset;
  width: 100%;
  clear: both;
  text-align: center;
  padding: 20px 0 0;

  li {
    padding: 0 5px 8px;
    display: inline-block;
    vertical-align: top;

    i {
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      background: $white;
      border-radius: 100%;
      overflow: hidden;
      color: $primary-color;
      text-align: center;
      white-space: nowrap;
      transition: background 200ms linear;

      &:before,
      &:after {
        display: inline-block;
        vertical-align: middle;
      }

      &:after {
        content: '';
        height: 100%;
      }
    }

    span {
      display: none;
    }

    a {
      &:hover {
        i {
          background: darken($white, 15);
        }
      }
    }
  }

  @include media('>=phone') {
    li {
      padding: 0 10px 8px;

      span {
        display: inline-block;
        vertical-align: middle;
        padding-left: 8px;
      }
    }
  }

  @include media('>=desktop') {
    float: right;
    width: 18%;
    clear: none;
    text-align: left;
    padding: 0;

    li {
      display: block;
      padding: 0 0 8px;
    }
  }
}

.payment-icons {
  @extend %listreset;
  text-align: center;
  padding-bottom: 20px;

  li {
    display: inline-block;
    vertical-align: middle;
    padding-left: 15px;
    padding-bottom: 10px
  }

  img {
    max-height: 30px;
  }

  @include media('>=desktop') {
    float: right;
    text-align: right;
    padding-bottom: 0;
  }
}

.copyright {
  display: block;
  overflow: hidden;
  padding: 10px 0;
  text-align: center;

  @include media('>=desktop') {
    text-align: left;
  }
}
