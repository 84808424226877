/** case page & articles styles */
.case-wrapper {
  padding: 0 0 20px;

  @include media('>=phone') {
    padding: 0 0 40px;
  }

  @include media('>=tablet') {
    padding: 0 0 80px;
    margin: 0 -20px;
  }

  @include media('>=desktop') {
    padding: 0 0 100px;
  }
}

.case-article {
  padding-top: 20px;
  max-width: 500px;
  margin: 0 auto;

  @include media('>=tablet') {
    padding-top: 40px;
    display: inline-block;
    vertical-align: top;
    width: 48%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include media('>=desktop') {
    padding-top: 50px;
  }

  @include media('>=widescreen') {
    padding-top: 70px;
  }

  .thumbnail {
    position: relative;
    margin-bottom: 10px;

    a {
      display: block;
      position: relative;
      transition: opacity 200ms linear;

      &:hover {
        opacity: 0.9;
      }
    }

    img {
      margin-bottom: 10px;
    }

    @include media('>=phone') {
      margin-bottom: 20px;

      img {
        margin-bottom: 0;
      }

      h3 {
        margin: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 22px;
        padding: 10px 20px;
        display: block;
        background: rgba($primary-color, .8);
        color: $white;
      }
    }
  }
}

.loadmore-section {
  text-align: center;
  padding: 30px 0;

  @include media('>=tablet') {
    padding: 0 0 60px;
  }
}
